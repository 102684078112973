import React, { useEffect, useState, Suspense, lazy } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import * as API from "@api/index";
import { View, Box, Button, Image, Text } from "@components/index";
import { useResponsive } from "@src/modules/hlibs/react-web/hook";
import ModalStack from "@hlibs/react-web/modals/ModalStack";
import Responsive from "@screens/Responsive";
import Loading from "@src/modules/hlibs/react-web/modals/Loading";

const ScreenMain = lazy(() => import("@screens/ScreenMain"));
const ScreenSignup = lazy(() => import("@screens/ScreenSignup"));
const ScreenVerify = lazy(() => import("@screens/ScreenVerify"));
const ScreenVerifyStatus = lazy(() => import("@screens/ScreenVerifyStatus"));
const ScreenApply = lazy(() => import("@screens/ScreenApply"));
const ScreenApplyCompany = lazy(() => import("@screens/ScreenApplyCompany"));
const ScreenApplyStatus = lazy(() => import("@screens/ScreenApplyStatus"));
const ScreenMyPage = lazy(() => import("@screens/ScreenMyPage"));
const ScreenChangePassword = lazy(
  () => import("@screens/ScreenChangePassword")
);
const ScreenLoginFind = lazy(() => import("@screens/ScreenLoginFind"));
const ScreenLogin = lazy(() => import("@screens/ScreenLogin"));
const ScreenPaymentSuccess = lazy(
  () => import("@screens/ScreenPaymentSuccess")
);
const ScreenPaymentDetail = lazy(() => import("@screens/ScreenPaymentDetail"));
const ScreenApplyCancel = lazy(() => import("@screens/ScreenApplyCancel"));

function MainRouter() {
  const navigate = useNavigate();
  const [init, setInit] = useState(false);

  useEffect(() => {
    setInit(true);
  }, []);
  return !init ? null : (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<ScreenMain />} />
        <Route path="/home" element={<ScreenMain />} />
        <Route path="/login" element={<ScreenLogin />} />
        <Route path="/signup" element={<ScreenSignup />} />
        <Route path="/creatorverify" element={<ScreenVerify />} />
        <Route path="/creatorverify_status" element={<ScreenVerifyStatus />} />
        <Route path="/apply" element={<ScreenApply />} />
        <Route path="/apply_company" element={<ScreenApplyCompany />} />
        <Route path="/apply_status" element={<ScreenApplyStatus />} />
        <Route path="/mypage" element={<ScreenMyPage />} />
        <Route path="/changepassword" element={<ScreenChangePassword />} />
        <Route path="/login/find" element={<ScreenLoginFind />} />
        {/* <Route path="/payment/success" element={<ScreenPaymentSuccess />} /> 
                <Route path="/payment" element={<ScreenPaymentDetail />} />  */}
        <Route path="/apply/cancel" element={<ScreenApplyCancel />} />
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </Suspense>
  );
}

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" Component={MainRouter} />
      </Routes>
      <ModalStack />
      <Responsive />
    </BrowserRouter>
  );
}
